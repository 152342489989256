/**
 * Generated by orval v6.10.3 🍺
 * Do not edit manually.
 * Oasis Indexer API V1
 * An API for accessing indexed data from the Oasis Network.
 * OpenAPI spec version: 0.1.0
 */
import axios from 'axios'
import type {
  AxiosRequestConfig,
  AxiosResponse,
  AxiosError
} from 'axios'
import {
  useQuery
} from '@tanstack/react-query'
import type {
  UseQueryOptions,
  QueryFunction,
  UseQueryResult,
  QueryKey
} from '@tanstack/react-query'
export type GetLayerStatsTxVolumeParams = { limit?: number; offset?: number; bucket_size_seconds?: number };

export type GetEmeraldEvmTokensParams = { limit?: number; offset?: number };

export type GetEmeraldEventsParams = { limit?: number; offset?: number; block?: number; tx_index?: number; tx_hash?: string; rel?: string; type?: RuntimeEventType; evm_log_signature?: string };

export type GetEmeraldTransactionsParams = { limit?: number; offset?: number; block?: number; rel?: string };

export type GetEmeraldBlocksParams = { limit?: number; offset?: number; from?: number; to?: number; after?: string; before?: string };

export type GetConsensusProposalsProposalIdVotesParams = { limit?: number; offset?: number };

export type GetConsensusProposalsParams = { limit?: number; offset?: number; submitter?: string; state?: string };

export type GetConsensusEpochsParams = { limit?: number; offset?: number };

export type GetConsensusAccountsAddressDebondingDelegationsParams = { limit?: number; offset?: number };

export type GetConsensusAccountsAddressDelegationsParams = { limit?: number; offset?: number };

export type GetConsensusAccountsParams = { limit?: number; offset?: number; minAvailable?: string; maxAvailable?: string; minEscrow?: string; maxEscrow?: string; minDebonding?: string; maxDebonding?: string; minTotalBalance?: string; maxTotalBalance?: string };

export type GetConsensusValidatorsParams = { limit?: number; offset?: number };

export type GetConsensusEntitiesEntityIdNodesParams = { limit?: number; offset?: number };

export type GetConsensusEntitiesParams = { limit?: number; offset?: number };

export type GetConsensusEventsParams = { limit?: number; offset?: number; block?: number; tx_index?: number; tx_hash?: string; rel?: string; type?: ConsensusEventType };

export type GetConsensusTransactionsParams = { limit?: number; offset?: number; block?: number; method?: ConsensusTxMethod; sender?: string; rel?: string; minFee?: string; maxFee?: string; code?: number };

export type GetConsensusBlocksParams = { limit?: number; offset?: number; from?: number; to?: number; after?: string; before?: string };

/**
 * An empty response indicating that the requested resource was not found.
 */
export type NotFoundErrorResponse = unknown;

export type HumanReadableErrorResponse = {
  /** An error message. */
  msg: string;
};

export interface TxVolume {
  /** The date for this daily transaction volume measurement. */
  bucket_start: string;
  /** The transaction volume on this day. */
  tx_volume: number;
}

/**
 * A list of daily transaction volumes.

 */
export interface TxVolumeList {
  bucket_size_seconds: number;
  /** The list of daily transaction volumes. */
  buckets: TxVolume[];
}

/**
 * A list of tokens in a runtime.
 */
export interface EvmTokenList {
  /** A list of L2 EVM tokens (ERC-20, ERC-271, ...). */
  evm_tokens: EvmToken[];
}

/**
 * The type of a EVM token.

 */
export type EvmTokenType = typeof EvmTokenType[keyof typeof EvmTokenType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EvmTokenType = {
  ERC20: 'ERC20',
  ERC721: 'ERC721',
  ERC1155: 'ERC1155',
  OasisSdk: 'OasisSdk',
} as const;

export interface EvmToken {
  /** The Oasis address of this token's contract. */
  contract_addr: string;
  /** The EVM address of this token's contract. Encoded as a lowercase hex string. */
  evm_contract_addr: string;
  /** Name of the token, as provided by token contract's `name()` method. */
  name?: string;
  /** Symbol of the token, as provided by token contract's `symbol()` method. */
  symbol?: string;
  /** The number of least significant digits in base units that should be displayed as
decimals when displaying tokens. `tokens = base_units / (10**decimals)`.
Affects display only. Often equals 18, to match ETH.
 */
  decimals?: number;
  /** The heuristically determined interface that the token contract implements.
A less specialized variant of the token might be detected; for example, an
ERC-1363 token might be labeled as ERC-20 here. If the type cannot be
detected or is not supported, this field will be null/absent.
 */
  type: EvmTokenType;
  /** The total number of base units available. */
  total_supply?: string;
  /** The number of addresses that have a nonzero balance of this token,
as calculated from Transfer events.
 */
  num_holders: number;
}

/**
 * The method call body.
 */
export type RuntimeTransactionBody = { [key: string]: any };

/**
 * A runtime transaction.

 */
export interface RuntimeTransaction {
  /** The block round at which this transaction was executed. */
  round: number;
  /** The 0-based index of this transaction in the block. */
  index: number;
  /** The second-granular consensus time when this tx's block was proposed. */
  timestamp: string;
  /** The Oasis cryptographic hash of this transaction's encoding. */
  hash: string;
  /** The Ethereum cryptographic hash of this transaction's encoding.
Absent for non-Ethereum-format transactions.
 */
  eth_hash?: string;
  /** The Oasis address of this transaction's 0th signer.
Unlike Ethereum, Oasis natively supports multiple-signature transactions.
However, the great majority of transactions only have a single signer in practice.
Retrieving the other signers is currently not supported by this API.
 */
  sender_0: string;
  /** The nonce used with this transaction's 0th signer, to prevent replay. */
  nonce_0: number;
  /** The fee that this transaction's sender committed to pay to execute
it (total, native denomination, ParaTime base units, as a string).
 */
  fee: string;
  /** The maximum gas that this transaction's sender committed to use to
execute it.
 */
  gas_limit: number;
  /** The method that was called. */
  method: string;
  /** The method call body. */
  body: RuntimeTransactionBody;
  /** A reasonable "to" Oasis address associated with this transaction,
if applicable. The meaning varies based on the transaction method. Some notable examples:
  - For `method = "accounts.Transfer"`, this is the paratime account receiving the funds.
  - For `method = "consensus.Deposit"`, this is the paratime account receiving the funds.
  - For `method = "consensus.Withdraw"`, this is a consensus (!) account receiving the funds.
  - For `method = "evm.Create"`, this is the address of the newly created smart contract.
  - For `method = "evm.Call"`, this is the address of the called smart contract
 */
  to?: string;
  /** A reasonable "amount" associated with this transaction, if
applicable. The meaning varies based on the transaction mehtod.
Usually in native denomination, ParaTime units. As a string.
 */
  amount?: string;
  /** Whether this transaction successfully executed. */
  success: boolean;
}

/**
 * A list of runtime transactions.

 */
export interface RuntimeTransactionList {
  transactions: RuntimeTransaction[];
}

export type RuntimeEventType = typeof RuntimeEventType[keyof typeof RuntimeEventType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RuntimeEventType = {
  accountstransfer: 'accounts.transfer',
  accountsburn: 'accounts.burn',
  accountsmint: 'accounts.mint',
  consensus_accountsdeposit: 'consensus_accounts.deposit',
  consensus_accountswithdraw: 'consensus_accounts.withdraw',
  coregas_used: 'core.gas_used',
  evmlog: 'evm.log',
} as const;

/**
 * The decoded `evm.log` event data. We currently support only two types of evm events, ERC20 `Transfer` 
and `Approve`.
Absent if the event type is not `evm.log`.

 */
export type RuntimeEventEvmLogParams = { [key: string]: any };

/**
 * The decoded event contents. This spec does not encode the many possible types;
instead, see [the Go API](https://pkg.go.dev/github.com/oasisprotocol/oasis-sdk/client-sdk/go/modules).
This object will conform to one of the `*Event` types two levels down
the hierarchy (e.g. `MintEvent` from `accounts > Event > MintEvent`),
OR `evm > Event`.

 */
export type RuntimeEventBody = { [key: string]: any };

/**
 * An event emitted by the runtime layer
 */
export interface RuntimeEvent {
  /** The block height at which this event was generated. */
  round: number;
  /** Hash of this event's originating transaction
Absent if the event did not originate from a transaction.
 */
  tx_hash?: string | null;
  /** The type of the event. */
  type: RuntimeEventType;
  /** The decoded event contents. This spec does not encode the many possible types;
instead, see [the Go API](https://pkg.go.dev/github.com/oasisprotocol/oasis-sdk/client-sdk/go/modules).
This object will conform to one of the `*Event` types two levels down
the hierarchy (e.g. `MintEvent` from `accounts > Event > MintEvent`),
OR `evm > Event`.
 */
  body: RuntimeEventBody;
  /** If the event type is `evm.log`, this field describes the human-readable type of evm event, e.g.
`Transfer`. We currently only support two types of evm events, ERC20 `Transfer` and `Approve`.
Absent if the event type is not `evm.log`.
 */
  evm_log_name?: string | null;
  /** The decoded `evm.log` event data. We currently support only two types of evm events, ERC20 `Transfer` 
and `Approve`.
Absent if the event type is not `evm.log`.
 */
  evm_log_params?: RuntimeEventEvmLogParams;
}

/**
 * A list of runtime events.

 */
export interface RuntimeEventList {
  events: RuntimeEvent[];
}

/**
 * A ParaTime block.

 */
export interface RuntimeBlock {
  /** The block round. */
  round: number;
  /** The block header hash. */
  hash: string;
  /** The second-granular consensus time. */
  timestamp: string;
  /** The number of transactions in the block. */
  num_transactions: number;
  /** The total byte size of all transactions in the block. */
  size: number;
  /** The total gas used by all transactions in the block. */
  gas_used: number;
}

/**
 * A list of consensus blocks.

 */
export interface RuntimeBlockList {
  blocks: RuntimeBlock[];
}

export interface ProposalVote {
  /** The staking address casting this vote. */
  address: string;
  /** The vote cast. */
  vote: string;
}

/**
 * A list of votes for a governance proposal.

 */
export interface ProposalVotes {
  /** The unique identifier of the proposal. */
  proposal_id: number;
  /** The list of votes for the proposal. */
  votes: ProposalVote[];
}

/**
 * The target propotocol versions for this upgrade proposal.
 */
export interface ProposalTarget {
  consensus_protocol?: string;
  runtime_host_protocol?: string;
  runtime_committee_protocol?: string;
}

/**
 * A governance proposal.

 */
export interface Proposal {
  /** The unique identifier of the proposal. */
  id: number;
  /** The staking address of the proposal submitter. */
  submitter: string;
  /** The state of the proposal. */
  state: string;
  /** The deposit attached to this proposal. */
  deposit: string;
  /** The name of the upgrade handler. */
  handler?: string;
  target?: ProposalTarget;
  /** The epoch at which the proposed upgrade will happen. */
  epoch?: number;
  /** The proposal to cancel, if this proposal proposes
cancelling an existing proposal.
 */
  cancels?: number;
  /** The epoch at which this proposal was created. */
  created_at: number;
  /** The epoch at which voting for this proposal will close. */
  closes_at: number;
  /** The number of invalid votes for this proposal, after tallying.
 */
  invalid_votes: string;
}

/**
 * A list of governance proposals.

 */
export interface ProposalList {
  proposals: Proposal[];
}

/**
 * A consensus epoch.

 */
export interface Epoch {
  /** The epoch number. */
  id: number;
  /** The (inclusive) height at which this epoch started. */
  start_height: number;
  /** The (inclusive) height at which this epoch ended. Omitted if the epoch is still active. */
  end_height?: number;
}

/**
 * A list of consensus epochs.

 */
export interface EpochList {
  epochs: Epoch[];
}

export interface Allowance {
  /** The allowed account. */
  address: string;
  /** The amount allowed for the allowed account. */
  amount: string;
}

/**
 * The name of a runtime. This is a human-readable identifier, and should
stay stable across runtime upgrades/versions.

 */
export type RuntimeName = typeof RuntimeName[keyof typeof RuntimeName];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RuntimeName = {
  emerald: 'emerald',
} as const;

/**
 * Balance of an account for a specific runtime and EVM token.
 */
export interface RuntimeEvmBalance {
  /** Number of tokens held, in base units. */
  balance: string;
  runtime: RuntimeName;
  /** The EVM address of this token's contract. Encoded as a lowercase hex string. */
  token_contract_addr: string;
  /** The token ticker symbol. Not guaranteed to be unique across distinct EVM tokens. */
  token_symbol?: string;
  /** The name of the token. Not guaranteed to be unique across distinct EVM tokens. */
  token_name?: string;
  token_type?: EvmTokenType;
  /** The number of decimals of precision for this token. */
  token_decimals: number;
}

/**
 * Balance of an account for a specific runtime and oasis-sdk token (e.g. ROSE).
 */
export interface RuntimeSdkBalance {
  /** Number of tokens held, in base units. */
  balance: string;
  runtime: RuntimeName;
  /** The token ticker symbol. Unique across all oasis-sdk tokens in the same runtime. */
  token_symbol: string;
  /** The number of decimals of precision for this token. */
  token_decimals: number;
}

/**
 * A consensus layer account.

 */
export interface Account {
  /** The staking address for this account. */
  address: string;
  address_preimage?: AddressPreimage;
  /** A nonce used to prevent replay. */
  nonce: number;
  /** The balances of this account in each runtime, as managed by oasis-sdk.
NOTE 1: This field is omitted for efficiency when listing multiple accounts.
NOTE 2: This field is limited to 1000 entries. If you need more, please let us know in a GitHub issue.
 */
  runtime_sdk_balances?: RuntimeSdkBalance[];
  /** The balances of this account in each runtime, as managed by EVM smart contracts (notably, ERC-20).
NOTE 1: This field is omitted for efficiency when listing multiple accounts.
NOTE 2: This field is limited to 1000 entries. If you need more, please let us know in a GitHub issue.
 */
  runtime_evm_balances?: RuntimeEvmBalance[];
  /** The available balance, in base units. */
  available: string;
  /** The active escrow balance, in base units. */
  escrow: string;
  /** The debonding escrow balance, in base units. */
  debonding: string;
  /** The delegations balance, in base units.
For efficiency, this field is omitted when listing multiple-accounts.
 */
  delegations_balance?: string;
  /** The debonding delegations balance, in base units.
For efficiency, this field is omitted when listing multiple-accounts.
 */
  debonding_delegations_balance?: string;
  /** The allowances made by this account. */
  allowances: Allowance[];
}

export type AddressDerivationContext = typeof AddressDerivationContext[keyof typeof AddressDerivationContext];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AddressDerivationContext = {
  'oasis-coreaddress_staking': 'oasis-core/address: staking',
  'oasis-runtime-sdkaddress_secp256k1eth': 'oasis-runtime-sdk/address: secp256k1eth',
  'oasis-runtime-sdkaddress_sr25519': 'oasis-runtime-sdk/address: sr25519',
  'oasis-runtime-sdkaddress_multisig': 'oasis-runtime-sdk/address: multisig',
  'oasis-runtime-sdkaddress_module': 'oasis-runtime-sdk/address: module',
  'oasis-runtime-sdkaddress_runtime': 'oasis-runtime-sdk/address: runtime',
} as const;

/**
 * The data from which a consensus-style address (`oasis1...`)
was derived. Notably, for EVM runtimes like Sapphire,
this links the oasis address and the Ethereum address.

Oasis addresses are derived from a piece of data, such as an ed25519
public key or an Ethereum address. For example, [this](https://github.com/oasisprotocol/oasis-sdk/blob/b37e6da699df331f5a2ac62793f8be099c68469c/client-sdk/go/helpers/address.go#L90-L91)
is how an Ethereum is converted to an oasis address. The type of underlying data usually also
determines how the signatuers for this address are verified.

Consensus supports only "staking addresses" (`context="oasis-core/address: staking"`
below; always ed25519-backed).
Runtimes support all types. This means that every consensus address is also
valid in every runtime. For example, in EVM runtimes, you can use staking
addresses, but only with oasis tools (e.g. a wallet); EVM contracts such as
ERC20 tokens or tools such as Metamask cannot interact with staking addresses.

 */
export interface AddressPreimage {
  /** The method by which the oasis address was derived from `address_data`.
 */
  context: AddressDerivationContext;
  /** Version of the `context`. */
  context_version?: number | null;
  /** The base64-encoded data from which the oasis address was derived.
When `context = "oasis-runtime-sdk/address: secp256k1eth"`, this
is the Ethereum address (in base64, not hex!).
 */
  address_data: string;
}

/**
 * A list of consensus layer accounts.

 */
export interface AccountList {
  accounts: Account[];
}

/**
 * A node registered at the consensus layer.

 */
export interface Node {
  /** The public key identifying this node. */
  id: string;
  /** The public key identifying the entity controlling this node.
 */
  entity_id: string;
  /** The epoch in which this node's commitment expires. */
  expiration: number;
  /** The public key used for establishing TLS connections. */
  tls_pubkey: string;
  /** The public key that will be used for establishing TLS connections
upon rotation.
 */
  tls_next_pubkey: string;
  /** The unique identifier of this node on the P2P transport. */
  p2p_pubkey: string;
  /** The unique identifier of this node as a consensus member */
  consensus_pubkey: string;
  /** A bitmask representing this node's roles. */
  roles: string;
}

/**
 * A list of nodes registered at the consensus layer.

 */
export interface NodeList {
  entity_id: string;
  nodes: Node[];
}

export interface ValidatorMedia {
  /** An URL associated with the entity. */
  website_link?: string;
  /** An email address for the validator. */
  email_address?: string;
  /** A Twitter handle. */
  twitter_acc?: string;
  /** An Telegram handle. */
  tg_chat?: string;
  /** A logo type. */
  logotype?: string;
  /** The human-readable name of this validator. */
  name?: string;
}

export interface ValidatorCommissionBound {
  lower: number;
  upper: number;
  epoch_start: number;
  epoch_end: number;
}

/**
 * An validator registered at the consensus layer.

 */
export interface Validator {
  /** The staking address identifying this Validator. */
  entity_address: string;
  /** The public key identifying this Validator. */
  entity_id: string;
  /** The public key identifying this Validator's node. */
  node_id: string;
  /** The amount staked. */
  escrow: string;
  /** Whether the entity is part of validator set (top <scheduler.params.max_validators> by stake). */
  active: boolean;
  /** Whether the entity has a node that is registered for being a validator, node is up to date, and has successfully registered itself. It may or may not be part of validator set. */
  status: boolean;
  media?: ValidatorMedia;
  /** Commission rate. */
  current_rate: number;
  current_commission_bound: ValidatorCommissionBound;
}

/**
 * A list of validators registered at the consensus layer.

 */
export interface ValidatorList {
  validators: Validator[];
}

/**
 * An entity registered at the consensus layer.

 */
export interface Entity {
  /** The public key identifying this entity. */
  id: string;
  /** The staking address belonging to this entity; derived from the entity's public key. */
  address: string;
  /** The vector of nodes owned by this entity. */
  nodes: string[];
}

/**
 * A list of entities registered at the consensus layer.

 */
export interface EntityList {
  entities: Entity[];
}

/**
 * The event contents. This spec does not encode the many possible types;
instead, see [the Go API](https://pkg.go.dev/github.com/oasisprotocol/oasis-core/go/consensus/api/transaction/results#Event) of oasis-core.
This object will conform to one of the `*Event` types two levels down
the hierarchy, e.g. `TransferEvent` from `Event > staking.Event > TransferEvent`

 */
export type ConsensusEventBody = { [key: string]: any };

/**
 * An event emitted by the consensus layer.

 */
export interface ConsensusEvent {
  /** The block height at which this event was generated. */
  block: number;
  /** 0-based index of this event's originating transaction within its block.
Absent if the event did not originate from a transaction.
 */
  tx_index?: number | null;
  /** Hash of this event's originating transaction.
Absent if the event did not originate from a transaction.
 */
  tx_hash?: string | null;
  /** The type of the event. */
  type: ConsensusEventType;
  /** The event contents. This spec does not encode the many possible types;
instead, see [the Go API](https://pkg.go.dev/github.com/oasisprotocol/oasis-core/go/consensus/api/transaction/results#Event) of oasis-core.
This object will conform to one of the `*Event` types two levels down
the hierarchy, e.g. `TransferEvent` from `Event > staking.Event > TransferEvent`
 */
  body: ConsensusEventBody;
}

/**
 * A list of consensus events.

 */
export interface ConsensusEventList {
  events: ConsensusEvent[];
}

export type ConsensusEventType = typeof ConsensusEventType[keyof typeof ConsensusEventType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ConsensusEventType = {
  governanceproposal_executed: 'governance.proposal_executed',
  governanceproposal_finalized: 'governance.proposal_finalized',
  governanceproposal_submitted: 'governance.proposal_submitted',
  governancevote: 'governance.vote',
  registryentity: 'registry.entity',
  registrynode_unfrozen: 'registry.node_unfrozen',
  registrynode: 'registry.node',
  registryruntime: 'registry.runtime',
  roothashexecution_discrepancy: 'roothash.execution_discrepancy',
  roothashexecutor_committed: 'roothash.executor_committed',
  roothashfinalized: 'roothash.finalized',
  stakingallowance_change: 'staking.allowance_change',
  stakingburn: 'staking.burn',
  stakingescrowadd: 'staking.escrow.add',
  stakingescrowdebonding_start: 'staking.escrow.debonding_start',
  stakingescrowreclaim: 'staking.escrow.reclaim',
  stakingescrowtake: 'staking.escrow.take',
  stakingtransfer: 'staking.transfer',
} as const;

/**
 * A list of consensus transactions.

 */
export interface TransactionList {
  transactions: Transaction[];
}

export type ConsensusTxMethod = typeof ConsensusTxMethod[keyof typeof ConsensusTxMethod];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ConsensusTxMethod = {
  stakingTransfer: 'staking.Transfer',
  stakingAddEscrow: 'staking.AddEscrow',
  stakingReclaimEscrow: 'staking.ReclaimEscrow',
  stakingAmendCommissionSchedule: 'staking.AmendCommissionSchedule',
  stakingAllow: 'staking.Allow',
  stakingWithdraw: 'staking.Withdraw',
  roothashExecutorCommit: 'roothash.ExecutorCommit',
  roothashExecutorProposerTimeout: 'roothash.ExecutorProposerTimeout',
  registryRegisterEntity: 'registry.RegisterEntity',
  registryRegisterNode: 'registry.RegisterNode',
  registryRegisterRuntime: 'registry.RegisterRuntime',
  governanceCastVote: 'governance.CastVote',
  governanceSubmitProposal: 'governance.SubmitProposal',
  beaconPVSSCommit: 'beacon.PVSSCommit',
  beaconPVSSReveal: 'beacon.PVSSReveal',
  beaconVRFProve: 'beacon.VRFProve',
} as const;

/**
 * A consensus transaction.

 */
export interface Transaction {
  /** The block height at which this transaction was executed. */
  block: number;
  /** 0-based index of this transaction in its block */
  index: number;
  /** The second-granular consensus time this tx's block, i.e. roughly when the
[block was proposed](https://github.com/tendermint/tendermint/blob/v0.34.x/spec/core/data_structures.md#header).
 */
  timestamp: string;
  /** The cryptographic hash of this transaction's encoding. */
  hash: string;
  /** The address of who sent this transaction. */
  sender: string;
  /** The nonce used with this transaction, to prevent replay. */
  nonce: number;
  /** The fee that this transaction's sender committed
to pay to execute it.
 */
  fee: string;
  /** The method that was called. */
  method: ConsensusTxMethod;
  /** The method call body. */
  body: string;
  /** Whether this transaction successfully executed. */
  success: boolean;
}

/**
 * A debonding delegation.

 */
export interface DebondingDelegation {
  /** The amount of tokens delegated in base units. */
  amount: string;
  /** The shares of tokens delegated. */
  shares: string;
  /** The delegatee address. */
  validator_address: string;
  /** The epoch at which the debonding ends. */
  debond_end: number;
}

/**
 * A list of debonding delegations.

 */
export interface DebondingDelegationList {
  debonding_delegations: DebondingDelegation[];
}

/**
 * A delegation.

 */
export interface Delegation {
  /** The amount of tokens delegated in base units. */
  amount: string;
  /** The shares of tokens delegated. */
  shares: string;
  /** The delegatee address. */
  validator_address: string;
}

/**
 * A list of delegations.

 */
export interface DelegationList {
  delegations: Delegation[];
}

/**
 * A consensus block.

 */
export interface Block {
  /** The block height. */
  height: number;
  /** The block header hash. */
  hash: string;
  /** The second-granular consensus time. */
  timestamp: string;
  /** Number of transactions in the block. */
  num_transactions: number;
}

/**
 * A list of consensus blocks.

 */
export interface BlockList {
  blocks: Block[];
}

export interface Status {
  /** The most recently indexed chain ID. */
  latest_chain_id: string;
  /** The height of the most recent indexed block. Query a synced Oasis node for the latest block produced. */
  latest_block: number;
  /** The RFC 3339 formatted time when the Indexer processed the latest block. Compare with current time for approximate indexing progress with the Oasis Network. */
  latest_update: string;
}

export type Layer = typeof Layer[keyof typeof Layer];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Layer = {
  consensus: 'consensus',
  emerald: 'emerald',
} as const;



type AwaitedInput<T> = PromiseLike<T> | T;

      type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;


/**
 * @summary Returns the indexer status.
 */
export const getStatus = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<Status>> => {
    return axios.get(
      `/`,options
    );
  }


export const getGetStatusQueryKey = () => [`/`];

    
export type GetStatusQueryResult = NonNullable<Awaited<ReturnType<typeof getStatus>>>
export type GetStatusQueryError = AxiosError<HumanReadableErrorResponse | NotFoundErrorResponse>

export const useGetStatus = <TData = Awaited<ReturnType<typeof getStatus>>, TError = AxiosError<HumanReadableErrorResponse | NotFoundErrorResponse>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getStatus>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetStatusQueryKey();

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getStatus>>> = ({ signal }) => getStatus({ signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof getStatus>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * @summary Returns a list of consensus blocks, sorted from most to least recent.
 */
export const getConsensusBlocks = (
    params?: GetConsensusBlocksParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<BlockList>> => {
    return axios.get(
      `/consensus/blocks`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }


export const getGetConsensusBlocksQueryKey = (params?: GetConsensusBlocksParams,) => [`/consensus/blocks`, ...(params ? [params]: [])];

    
export type GetConsensusBlocksQueryResult = NonNullable<Awaited<ReturnType<typeof getConsensusBlocks>>>
export type GetConsensusBlocksQueryError = AxiosError<HumanReadableErrorResponse | NotFoundErrorResponse>

export const useGetConsensusBlocks = <TData = Awaited<ReturnType<typeof getConsensusBlocks>>, TError = AxiosError<HumanReadableErrorResponse | NotFoundErrorResponse>>(
 params?: GetConsensusBlocksParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getConsensusBlocks>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetConsensusBlocksQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getConsensusBlocks>>> = ({ signal }) => getConsensusBlocks(params, { signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof getConsensusBlocks>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * @summary Returns a consensus block.
 */
export const getConsensusBlocksHeight = (
    height: number, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<Block>> => {
    return axios.get(
      `/consensus/blocks/${height}`,options
    );
  }


export const getGetConsensusBlocksHeightQueryKey = (height: number,) => [`/consensus/blocks/${height}`];

    
export type GetConsensusBlocksHeightQueryResult = NonNullable<Awaited<ReturnType<typeof getConsensusBlocksHeight>>>
export type GetConsensusBlocksHeightQueryError = AxiosError<HumanReadableErrorResponse | NotFoundErrorResponse>

export const useGetConsensusBlocksHeight = <TData = Awaited<ReturnType<typeof getConsensusBlocksHeight>>, TError = AxiosError<HumanReadableErrorResponse | NotFoundErrorResponse>>(
 height: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getConsensusBlocksHeight>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetConsensusBlocksHeightQueryKey(height);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getConsensusBlocksHeight>>> = ({ signal }) => getConsensusBlocksHeight(height, { signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof getConsensusBlocksHeight>>, TError, TData>(queryKey, queryFn, {enabled: !!(height), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * @summary Returns a list of consensus transactions.
 */
export const getConsensusTransactions = (
    params?: GetConsensusTransactionsParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<TransactionList>> => {
    return axios.get(
      `/consensus/transactions`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }


export const getGetConsensusTransactionsQueryKey = (params?: GetConsensusTransactionsParams,) => [`/consensus/transactions`, ...(params ? [params]: [])];

    
export type GetConsensusTransactionsQueryResult = NonNullable<Awaited<ReturnType<typeof getConsensusTransactions>>>
export type GetConsensusTransactionsQueryError = AxiosError<HumanReadableErrorResponse | NotFoundErrorResponse>

export const useGetConsensusTransactions = <TData = Awaited<ReturnType<typeof getConsensusTransactions>>, TError = AxiosError<HumanReadableErrorResponse | NotFoundErrorResponse>>(
 params?: GetConsensusTransactionsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getConsensusTransactions>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetConsensusTransactionsQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getConsensusTransactions>>> = ({ signal }) => getConsensusTransactions(params, { signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof getConsensusTransactions>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * @summary Returns a consensus transaction.
 */
export const getConsensusTransactionsTxHash = (
    txHash: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<Transaction>> => {
    return axios.get(
      `/consensus/transactions/${txHash}`,options
    );
  }


export const getGetConsensusTransactionsTxHashQueryKey = (txHash: string,) => [`/consensus/transactions/${txHash}`];

    
export type GetConsensusTransactionsTxHashQueryResult = NonNullable<Awaited<ReturnType<typeof getConsensusTransactionsTxHash>>>
export type GetConsensusTransactionsTxHashQueryError = AxiosError<HumanReadableErrorResponse | NotFoundErrorResponse>

export const useGetConsensusTransactionsTxHash = <TData = Awaited<ReturnType<typeof getConsensusTransactionsTxHash>>, TError = AxiosError<HumanReadableErrorResponse | NotFoundErrorResponse>>(
 txHash: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getConsensusTransactionsTxHash>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetConsensusTransactionsTxHashQueryKey(txHash);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getConsensusTransactionsTxHash>>> = ({ signal }) => getConsensusTransactionsTxHash(txHash, { signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof getConsensusTransactionsTxHash>>, TError, TData>(queryKey, queryFn, {enabled: !!(txHash), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * @summary Returns a list of consensus events.
 */
export const getConsensusEvents = (
    params?: GetConsensusEventsParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<ConsensusEventList>> => {
    return axios.get(
      `/consensus/events`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }


export const getGetConsensusEventsQueryKey = (params?: GetConsensusEventsParams,) => [`/consensus/events`, ...(params ? [params]: [])];

    
export type GetConsensusEventsQueryResult = NonNullable<Awaited<ReturnType<typeof getConsensusEvents>>>
export type GetConsensusEventsQueryError = AxiosError<HumanReadableErrorResponse | NotFoundErrorResponse>

export const useGetConsensusEvents = <TData = Awaited<ReturnType<typeof getConsensusEvents>>, TError = AxiosError<HumanReadableErrorResponse | NotFoundErrorResponse>>(
 params?: GetConsensusEventsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getConsensusEvents>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetConsensusEventsQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getConsensusEvents>>> = ({ signal }) => getConsensusEvents(params, { signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof getConsensusEvents>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * @summary Returns a list of entities registered at the consensus layer.
 */
export const getConsensusEntities = (
    params?: GetConsensusEntitiesParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<EntityList>> => {
    return axios.get(
      `/consensus/entities`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }


export const getGetConsensusEntitiesQueryKey = (params?: GetConsensusEntitiesParams,) => [`/consensus/entities`, ...(params ? [params]: [])];

    
export type GetConsensusEntitiesQueryResult = NonNullable<Awaited<ReturnType<typeof getConsensusEntities>>>
export type GetConsensusEntitiesQueryError = AxiosError<HumanReadableErrorResponse | NotFoundErrorResponse>

export const useGetConsensusEntities = <TData = Awaited<ReturnType<typeof getConsensusEntities>>, TError = AxiosError<HumanReadableErrorResponse | NotFoundErrorResponse>>(
 params?: GetConsensusEntitiesParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getConsensusEntities>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetConsensusEntitiesQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getConsensusEntities>>> = ({ signal }) => getConsensusEntities(params, { signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof getConsensusEntities>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * @summary Returns an entity registered at the consensus layer.
 */
export const getConsensusEntitiesEntityId = (
    entityId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<Entity>> => {
    return axios.get(
      `/consensus/entities/${entityId}`,options
    );
  }


export const getGetConsensusEntitiesEntityIdQueryKey = (entityId: string,) => [`/consensus/entities/${entityId}`];

    
export type GetConsensusEntitiesEntityIdQueryResult = NonNullable<Awaited<ReturnType<typeof getConsensusEntitiesEntityId>>>
export type GetConsensusEntitiesEntityIdQueryError = AxiosError<HumanReadableErrorResponse | NotFoundErrorResponse>

export const useGetConsensusEntitiesEntityId = <TData = Awaited<ReturnType<typeof getConsensusEntitiesEntityId>>, TError = AxiosError<HumanReadableErrorResponse | NotFoundErrorResponse>>(
 entityId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getConsensusEntitiesEntityId>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetConsensusEntitiesEntityIdQueryKey(entityId);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getConsensusEntitiesEntityId>>> = ({ signal }) => getConsensusEntitiesEntityId(entityId, { signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof getConsensusEntitiesEntityId>>, TError, TData>(queryKey, queryFn, {enabled: !!(entityId), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * @summary Returns a list of nodes registered at the consensus layer.
 */
export const getConsensusEntitiesEntityIdNodes = (
    entityId: string,
    params?: GetConsensusEntitiesEntityIdNodesParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<NodeList>> => {
    return axios.get(
      `/consensus/entities/${entityId}/nodes`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }


export const getGetConsensusEntitiesEntityIdNodesQueryKey = (entityId: string,
    params?: GetConsensusEntitiesEntityIdNodesParams,) => [`/consensus/entities/${entityId}/nodes`, ...(params ? [params]: [])];

    
export type GetConsensusEntitiesEntityIdNodesQueryResult = NonNullable<Awaited<ReturnType<typeof getConsensusEntitiesEntityIdNodes>>>
export type GetConsensusEntitiesEntityIdNodesQueryError = AxiosError<HumanReadableErrorResponse | NotFoundErrorResponse>

export const useGetConsensusEntitiesEntityIdNodes = <TData = Awaited<ReturnType<typeof getConsensusEntitiesEntityIdNodes>>, TError = AxiosError<HumanReadableErrorResponse | NotFoundErrorResponse>>(
 entityId: string,
    params?: GetConsensusEntitiesEntityIdNodesParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getConsensusEntitiesEntityIdNodes>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetConsensusEntitiesEntityIdNodesQueryKey(entityId,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getConsensusEntitiesEntityIdNodes>>> = ({ signal }) => getConsensusEntitiesEntityIdNodes(entityId,params, { signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof getConsensusEntitiesEntityIdNodes>>, TError, TData>(queryKey, queryFn, {enabled: !!(entityId), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * @summary Returns a node registered at the consensus layer.
 */
export const getConsensusEntitiesEntityIdNodesNodeId = (
    entityId: string,
    nodeId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<Node>> => {
    return axios.get(
      `/consensus/entities/${entityId}/nodes/${nodeId}`,options
    );
  }


export const getGetConsensusEntitiesEntityIdNodesNodeIdQueryKey = (entityId: string,
    nodeId: string,) => [`/consensus/entities/${entityId}/nodes/${nodeId}`];

    
export type GetConsensusEntitiesEntityIdNodesNodeIdQueryResult = NonNullable<Awaited<ReturnType<typeof getConsensusEntitiesEntityIdNodesNodeId>>>
export type GetConsensusEntitiesEntityIdNodesNodeIdQueryError = AxiosError<HumanReadableErrorResponse | NotFoundErrorResponse>

export const useGetConsensusEntitiesEntityIdNodesNodeId = <TData = Awaited<ReturnType<typeof getConsensusEntitiesEntityIdNodesNodeId>>, TError = AxiosError<HumanReadableErrorResponse | NotFoundErrorResponse>>(
 entityId: string,
    nodeId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getConsensusEntitiesEntityIdNodesNodeId>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetConsensusEntitiesEntityIdNodesNodeIdQueryKey(entityId,nodeId);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getConsensusEntitiesEntityIdNodesNodeId>>> = ({ signal }) => getConsensusEntitiesEntityIdNodesNodeId(entityId,nodeId, { signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof getConsensusEntitiesEntityIdNodesNodeId>>, TError, TData>(queryKey, queryFn, {enabled: !!(entityId && nodeId), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * @summary Returns a list of validators registered at the consensus layer.
 */
export const getConsensusValidators = (
    params?: GetConsensusValidatorsParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<ValidatorList>> => {
    return axios.get(
      `/consensus/validators`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }


export const getGetConsensusValidatorsQueryKey = (params?: GetConsensusValidatorsParams,) => [`/consensus/validators`, ...(params ? [params]: [])];

    
export type GetConsensusValidatorsQueryResult = NonNullable<Awaited<ReturnType<typeof getConsensusValidators>>>
export type GetConsensusValidatorsQueryError = AxiosError<HumanReadableErrorResponse | NotFoundErrorResponse>

export const useGetConsensusValidators = <TData = Awaited<ReturnType<typeof getConsensusValidators>>, TError = AxiosError<HumanReadableErrorResponse | NotFoundErrorResponse>>(
 params?: GetConsensusValidatorsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getConsensusValidators>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetConsensusValidatorsQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getConsensusValidators>>> = ({ signal }) => getConsensusValidators(params, { signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof getConsensusValidators>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * @summary Returns a validator registered at the consensus layer.
 */
export const getConsensusValidatorsEntityId = (
    entityId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<Validator>> => {
    return axios.get(
      `/consensus/validators/${entityId}`,options
    );
  }


export const getGetConsensusValidatorsEntityIdQueryKey = (entityId: string,) => [`/consensus/validators/${entityId}`];

    
export type GetConsensusValidatorsEntityIdQueryResult = NonNullable<Awaited<ReturnType<typeof getConsensusValidatorsEntityId>>>
export type GetConsensusValidatorsEntityIdQueryError = AxiosError<HumanReadableErrorResponse | NotFoundErrorResponse>

export const useGetConsensusValidatorsEntityId = <TData = Awaited<ReturnType<typeof getConsensusValidatorsEntityId>>, TError = AxiosError<HumanReadableErrorResponse | NotFoundErrorResponse>>(
 entityId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getConsensusValidatorsEntityId>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetConsensusValidatorsEntityIdQueryKey(entityId);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getConsensusValidatorsEntityId>>> = ({ signal }) => getConsensusValidatorsEntityId(entityId, { signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof getConsensusValidatorsEntityId>>, TError, TData>(queryKey, queryFn, {enabled: !!(entityId), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * @summary Returns a list of consensus layer accounts.
 */
export const getConsensusAccounts = (
    params?: GetConsensusAccountsParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<AccountList>> => {
    return axios.get(
      `/consensus/accounts`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }


export const getGetConsensusAccountsQueryKey = (params?: GetConsensusAccountsParams,) => [`/consensus/accounts`, ...(params ? [params]: [])];

    
export type GetConsensusAccountsQueryResult = NonNullable<Awaited<ReturnType<typeof getConsensusAccounts>>>
export type GetConsensusAccountsQueryError = AxiosError<HumanReadableErrorResponse | NotFoundErrorResponse>

export const useGetConsensusAccounts = <TData = Awaited<ReturnType<typeof getConsensusAccounts>>, TError = AxiosError<HumanReadableErrorResponse | NotFoundErrorResponse>>(
 params?: GetConsensusAccountsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getConsensusAccounts>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetConsensusAccountsQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getConsensusAccounts>>> = ({ signal }) => getConsensusAccounts(params, { signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof getConsensusAccounts>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * @summary Returns a consensus layer account.
 */
export const getConsensusAccountsAddress = (
    address: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<Account>> => {
    return axios.get(
      `/consensus/accounts/${address}`,options
    );
  }


export const getGetConsensusAccountsAddressQueryKey = (address: string,) => [`/consensus/accounts/${address}`];

    
export type GetConsensusAccountsAddressQueryResult = NonNullable<Awaited<ReturnType<typeof getConsensusAccountsAddress>>>
export type GetConsensusAccountsAddressQueryError = AxiosError<HumanReadableErrorResponse | NotFoundErrorResponse>

export const useGetConsensusAccountsAddress = <TData = Awaited<ReturnType<typeof getConsensusAccountsAddress>>, TError = AxiosError<HumanReadableErrorResponse | NotFoundErrorResponse>>(
 address: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getConsensusAccountsAddress>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetConsensusAccountsAddressQueryKey(address);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getConsensusAccountsAddress>>> = ({ signal }) => getConsensusAccountsAddress(address, { signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof getConsensusAccountsAddress>>, TError, TData>(queryKey, queryFn, {enabled: !!(address), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * @summary Returns an account's delegations.
 */
export const getConsensusAccountsAddressDelegations = (
    address: string,
    params?: GetConsensusAccountsAddressDelegationsParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<DelegationList>> => {
    return axios.get(
      `/consensus/accounts/${address}/delegations`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }


export const getGetConsensusAccountsAddressDelegationsQueryKey = (address: string,
    params?: GetConsensusAccountsAddressDelegationsParams,) => [`/consensus/accounts/${address}/delegations`, ...(params ? [params]: [])];

    
export type GetConsensusAccountsAddressDelegationsQueryResult = NonNullable<Awaited<ReturnType<typeof getConsensusAccountsAddressDelegations>>>
export type GetConsensusAccountsAddressDelegationsQueryError = AxiosError<HumanReadableErrorResponse | NotFoundErrorResponse>

export const useGetConsensusAccountsAddressDelegations = <TData = Awaited<ReturnType<typeof getConsensusAccountsAddressDelegations>>, TError = AxiosError<HumanReadableErrorResponse | NotFoundErrorResponse>>(
 address: string,
    params?: GetConsensusAccountsAddressDelegationsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getConsensusAccountsAddressDelegations>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetConsensusAccountsAddressDelegationsQueryKey(address,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getConsensusAccountsAddressDelegations>>> = ({ signal }) => getConsensusAccountsAddressDelegations(address,params, { signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof getConsensusAccountsAddressDelegations>>, TError, TData>(queryKey, queryFn, {enabled: !!(address), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * @summary Returns an account's debonding delegations.
 */
export const getConsensusAccountsAddressDebondingDelegations = (
    address: string,
    params?: GetConsensusAccountsAddressDebondingDelegationsParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<DebondingDelegationList>> => {
    return axios.get(
      `/consensus/accounts/${address}/debonding_delegations`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }


export const getGetConsensusAccountsAddressDebondingDelegationsQueryKey = (address: string,
    params?: GetConsensusAccountsAddressDebondingDelegationsParams,) => [`/consensus/accounts/${address}/debonding_delegations`, ...(params ? [params]: [])];

    
export type GetConsensusAccountsAddressDebondingDelegationsQueryResult = NonNullable<Awaited<ReturnType<typeof getConsensusAccountsAddressDebondingDelegations>>>
export type GetConsensusAccountsAddressDebondingDelegationsQueryError = AxiosError<HumanReadableErrorResponse | NotFoundErrorResponse>

export const useGetConsensusAccountsAddressDebondingDelegations = <TData = Awaited<ReturnType<typeof getConsensusAccountsAddressDebondingDelegations>>, TError = AxiosError<HumanReadableErrorResponse | NotFoundErrorResponse>>(
 address: string,
    params?: GetConsensusAccountsAddressDebondingDelegationsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getConsensusAccountsAddressDebondingDelegations>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetConsensusAccountsAddressDebondingDelegationsQueryKey(address,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getConsensusAccountsAddressDebondingDelegations>>> = ({ signal }) => getConsensusAccountsAddressDebondingDelegations(address,params, { signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof getConsensusAccountsAddressDebondingDelegations>>, TError, TData>(queryKey, queryFn, {enabled: !!(address), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * @summary Returns a list of consensus epochs.
 */
export const getConsensusEpochs = (
    params?: GetConsensusEpochsParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<EpochList>> => {
    return axios.get(
      `/consensus/epochs`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }


export const getGetConsensusEpochsQueryKey = (params?: GetConsensusEpochsParams,) => [`/consensus/epochs`, ...(params ? [params]: [])];

    
export type GetConsensusEpochsQueryResult = NonNullable<Awaited<ReturnType<typeof getConsensusEpochs>>>
export type GetConsensusEpochsQueryError = AxiosError<HumanReadableErrorResponse | NotFoundErrorResponse>

export const useGetConsensusEpochs = <TData = Awaited<ReturnType<typeof getConsensusEpochs>>, TError = AxiosError<HumanReadableErrorResponse | NotFoundErrorResponse>>(
 params?: GetConsensusEpochsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getConsensusEpochs>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetConsensusEpochsQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getConsensusEpochs>>> = ({ signal }) => getConsensusEpochs(params, { signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof getConsensusEpochs>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * @summary Returns a consensus epoch.
 */
export const getConsensusEpochsEpoch = (
    epoch: number, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<Epoch>> => {
    return axios.get(
      `/consensus/epochs/${epoch}`,options
    );
  }


export const getGetConsensusEpochsEpochQueryKey = (epoch: number,) => [`/consensus/epochs/${epoch}`];

    
export type GetConsensusEpochsEpochQueryResult = NonNullable<Awaited<ReturnType<typeof getConsensusEpochsEpoch>>>
export type GetConsensusEpochsEpochQueryError = AxiosError<HumanReadableErrorResponse | NotFoundErrorResponse>

export const useGetConsensusEpochsEpoch = <TData = Awaited<ReturnType<typeof getConsensusEpochsEpoch>>, TError = AxiosError<HumanReadableErrorResponse | NotFoundErrorResponse>>(
 epoch: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getConsensusEpochsEpoch>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetConsensusEpochsEpochQueryKey(epoch);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getConsensusEpochsEpoch>>> = ({ signal }) => getConsensusEpochsEpoch(epoch, { signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof getConsensusEpochsEpoch>>, TError, TData>(queryKey, queryFn, {enabled: !!(epoch), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * @summary Returns a list of governance proposals.
 */
export const getConsensusProposals = (
    params?: GetConsensusProposalsParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<ProposalList>> => {
    return axios.get(
      `/consensus/proposals`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }


export const getGetConsensusProposalsQueryKey = (params?: GetConsensusProposalsParams,) => [`/consensus/proposals`, ...(params ? [params]: [])];

    
export type GetConsensusProposalsQueryResult = NonNullable<Awaited<ReturnType<typeof getConsensusProposals>>>
export type GetConsensusProposalsQueryError = AxiosError<HumanReadableErrorResponse | NotFoundErrorResponse>

export const useGetConsensusProposals = <TData = Awaited<ReturnType<typeof getConsensusProposals>>, TError = AxiosError<HumanReadableErrorResponse | NotFoundErrorResponse>>(
 params?: GetConsensusProposalsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getConsensusProposals>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetConsensusProposalsQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getConsensusProposals>>> = ({ signal }) => getConsensusProposals(params, { signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof getConsensusProposals>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * @summary Returns a governance proposal.
 */
export const getConsensusProposalsProposalId = (
    proposalId: number, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<Proposal>> => {
    return axios.get(
      `/consensus/proposals/${proposalId}`,options
    );
  }


export const getGetConsensusProposalsProposalIdQueryKey = (proposalId: number,) => [`/consensus/proposals/${proposalId}`];

    
export type GetConsensusProposalsProposalIdQueryResult = NonNullable<Awaited<ReturnType<typeof getConsensusProposalsProposalId>>>
export type GetConsensusProposalsProposalIdQueryError = AxiosError<HumanReadableErrorResponse | NotFoundErrorResponse>

export const useGetConsensusProposalsProposalId = <TData = Awaited<ReturnType<typeof getConsensusProposalsProposalId>>, TError = AxiosError<HumanReadableErrorResponse | NotFoundErrorResponse>>(
 proposalId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getConsensusProposalsProposalId>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetConsensusProposalsProposalIdQueryKey(proposalId);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getConsensusProposalsProposalId>>> = ({ signal }) => getConsensusProposalsProposalId(proposalId, { signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof getConsensusProposalsProposalId>>, TError, TData>(queryKey, queryFn, {enabled: !!(proposalId), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * @summary Returns a list of votes for a governance proposal.
 */
export const getConsensusProposalsProposalIdVotes = (
    proposalId: number,
    params?: GetConsensusProposalsProposalIdVotesParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<ProposalVotes>> => {
    return axios.get(
      `/consensus/proposals/${proposalId}/votes`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }


export const getGetConsensusProposalsProposalIdVotesQueryKey = (proposalId: number,
    params?: GetConsensusProposalsProposalIdVotesParams,) => [`/consensus/proposals/${proposalId}/votes`, ...(params ? [params]: [])];

    
export type GetConsensusProposalsProposalIdVotesQueryResult = NonNullable<Awaited<ReturnType<typeof getConsensusProposalsProposalIdVotes>>>
export type GetConsensusProposalsProposalIdVotesQueryError = AxiosError<HumanReadableErrorResponse | NotFoundErrorResponse>

export const useGetConsensusProposalsProposalIdVotes = <TData = Awaited<ReturnType<typeof getConsensusProposalsProposalIdVotes>>, TError = AxiosError<HumanReadableErrorResponse | NotFoundErrorResponse>>(
 proposalId: number,
    params?: GetConsensusProposalsProposalIdVotesParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getConsensusProposalsProposalIdVotes>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetConsensusProposalsProposalIdVotesQueryKey(proposalId,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getConsensusProposalsProposalIdVotes>>> = ({ signal }) => getConsensusProposalsProposalIdVotes(proposalId,params, { signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof getConsensusProposalsProposalIdVotes>>, TError, TData>(queryKey, queryFn, {enabled: !!(proposalId), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * @summary Returns a list of Emerald blocks.
 */
export const getEmeraldBlocks = (
    params?: GetEmeraldBlocksParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<RuntimeBlockList>> => {
    return axios.get(
      `/emerald/blocks`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }


export const getGetEmeraldBlocksQueryKey = (params?: GetEmeraldBlocksParams,) => [`/emerald/blocks`, ...(params ? [params]: [])];

    
export type GetEmeraldBlocksQueryResult = NonNullable<Awaited<ReturnType<typeof getEmeraldBlocks>>>
export type GetEmeraldBlocksQueryError = AxiosError<HumanReadableErrorResponse | NotFoundErrorResponse>

export const useGetEmeraldBlocks = <TData = Awaited<ReturnType<typeof getEmeraldBlocks>>, TError = AxiosError<HumanReadableErrorResponse | NotFoundErrorResponse>>(
 params?: GetEmeraldBlocksParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getEmeraldBlocks>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetEmeraldBlocksQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getEmeraldBlocks>>> = ({ signal }) => getEmeraldBlocks(params, { signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof getEmeraldBlocks>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * @summary Returns a list of Emerald transactions.
 */
export const getEmeraldTransactions = (
    params?: GetEmeraldTransactionsParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<RuntimeTransactionList>> => {
    return axios.get(
      `/emerald/transactions`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }


export const getGetEmeraldTransactionsQueryKey = (params?: GetEmeraldTransactionsParams,) => [`/emerald/transactions`, ...(params ? [params]: [])];

    
export type GetEmeraldTransactionsQueryResult = NonNullable<Awaited<ReturnType<typeof getEmeraldTransactions>>>
export type GetEmeraldTransactionsQueryError = AxiosError<HumanReadableErrorResponse | NotFoundErrorResponse>

export const useGetEmeraldTransactions = <TData = Awaited<ReturnType<typeof getEmeraldTransactions>>, TError = AxiosError<HumanReadableErrorResponse | NotFoundErrorResponse>>(
 params?: GetEmeraldTransactionsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getEmeraldTransactions>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetEmeraldTransactionsQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getEmeraldTransactions>>> = ({ signal }) => getEmeraldTransactions(params, { signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof getEmeraldTransactions>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * @summary Returns a list of Emerald events.
 */
export const getEmeraldEvents = (
    params?: GetEmeraldEventsParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<RuntimeEventList>> => {
    return axios.get(
      `/emerald/events`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }


export const getGetEmeraldEventsQueryKey = (params?: GetEmeraldEventsParams,) => [`/emerald/events`, ...(params ? [params]: [])];

    
export type GetEmeraldEventsQueryResult = NonNullable<Awaited<ReturnType<typeof getEmeraldEvents>>>
export type GetEmeraldEventsQueryError = AxiosError<HumanReadableErrorResponse | NotFoundErrorResponse>

export const useGetEmeraldEvents = <TData = Awaited<ReturnType<typeof getEmeraldEvents>>, TError = AxiosError<HumanReadableErrorResponse | NotFoundErrorResponse>>(
 params?: GetEmeraldEventsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getEmeraldEvents>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetEmeraldEventsQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getEmeraldEvents>>> = ({ signal }) => getEmeraldEvents(params, { signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof getEmeraldEvents>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * @summary Returns an Emerald transaction with the given transaction hash.
 */
export const getEmeraldTransactionsTxHash = (
    txHash: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<RuntimeTransaction>> => {
    return axios.get(
      `/emerald/transactions/${txHash}`,options
    );
  }


export const getGetEmeraldTransactionsTxHashQueryKey = (txHash: string,) => [`/emerald/transactions/${txHash}`];

    
export type GetEmeraldTransactionsTxHashQueryResult = NonNullable<Awaited<ReturnType<typeof getEmeraldTransactionsTxHash>>>
export type GetEmeraldTransactionsTxHashQueryError = AxiosError<HumanReadableErrorResponse | NotFoundErrorResponse>

export const useGetEmeraldTransactionsTxHash = <TData = Awaited<ReturnType<typeof getEmeraldTransactionsTxHash>>, TError = AxiosError<HumanReadableErrorResponse | NotFoundErrorResponse>>(
 txHash: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getEmeraldTransactionsTxHash>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetEmeraldTransactionsTxHashQueryKey(txHash);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getEmeraldTransactionsTxHash>>> = ({ signal }) => getEmeraldTransactionsTxHash(txHash, { signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof getEmeraldTransactionsTxHash>>, TError, TData>(queryKey, queryFn, {enabled: !!(txHash), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * @summary Returns a list of EVM (ERC-20, ...) tokens on Emerald.
 */
export const getEmeraldEvmTokens = (
    params?: GetEmeraldEvmTokensParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<EvmTokenList>> => {
    return axios.get(
      `/emerald/evm_tokens`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }


export const getGetEmeraldEvmTokensQueryKey = (params?: GetEmeraldEvmTokensParams,) => [`/emerald/evm_tokens`, ...(params ? [params]: [])];

    
export type GetEmeraldEvmTokensQueryResult = NonNullable<Awaited<ReturnType<typeof getEmeraldEvmTokens>>>
export type GetEmeraldEvmTokensQueryError = AxiosError<HumanReadableErrorResponse | NotFoundErrorResponse>

export const useGetEmeraldEvmTokens = <TData = Awaited<ReturnType<typeof getEmeraldEvmTokens>>, TError = AxiosError<HumanReadableErrorResponse | NotFoundErrorResponse>>(
 params?: GetEmeraldEvmTokensParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getEmeraldEvmTokens>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetEmeraldEvmTokensQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getEmeraldEvmTokens>>> = ({ signal }) => getEmeraldEvmTokens(params, { signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof getEmeraldEvmTokens>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * @summary Returns a timeline of the transaction volume at the chosen granularity,
for either consensus or one of the paratimes.

 */
export const getLayerStatsTxVolume = (
    layer: Layer,
    params?: GetLayerStatsTxVolumeParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<TxVolumeList>> => {
    return axios.get(
      `/${layer}/stats/tx_volume`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }


export const getGetLayerStatsTxVolumeQueryKey = (layer: Layer,
    params?: GetLayerStatsTxVolumeParams,) => [`/${layer}/stats/tx_volume`, ...(params ? [params]: [])];

    
export type GetLayerStatsTxVolumeQueryResult = NonNullable<Awaited<ReturnType<typeof getLayerStatsTxVolume>>>
export type GetLayerStatsTxVolumeQueryError = AxiosError<HumanReadableErrorResponse | NotFoundErrorResponse>

export const useGetLayerStatsTxVolume = <TData = Awaited<ReturnType<typeof getLayerStatsTxVolume>>, TError = AxiosError<HumanReadableErrorResponse | NotFoundErrorResponse>>(
 layer: Layer,
    params?: GetLayerStatsTxVolumeParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getLayerStatsTxVolume>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetLayerStatsTxVolumeQueryKey(layer,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getLayerStatsTxVolume>>> = ({ signal }) => getLayerStatsTxVolume(layer,params, { signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof getLayerStatsTxVolume>>, TError, TData>(queryKey, queryFn, {enabled: !!(layer), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


