{
  "account": {
    "chain": "Chain",
    "emptyTokenList": "This account holds no {{token}} tokens.",
    "ERC20": "ERC-20",
    "ERC721": "ERC-721",
    "noTokens": "This account holds no tokens",
    "showMore": "+ {{counter}} more",
    "title": "Account",
    "evmTokens": "EVM tokens",
    "tokensListTitle": "{{token}} Tokens",
    "transactionsListTitle": "Account Transactions",
    "totalReceived": "Total Received",
    "totalSent": "Total Sent"
  },
  "activeAccounts": {
    "title": "Active Accounts"
  },
  "averageTransactionSize": {
    "header": "Average Transaction Size",
    "tooltip": "Average Tx Size: {{value, number}}"
  },
  "blocks": {
    "latest": "Latest Blocks"
  },
  "block": {
    "gasUsed": "{{value, number}} | {{percentage, number}}"
  },
  "clipboard": {
    "label": "Copy to clipboard",
    "success": "Copied!"
  },
  "coinGeckoReferral": "Data from <CoinGeckoLink>CoinGecko</CoinGeckoLink>",
  "common": {
    "age": "Age",
    "balance": "Balance",
    "block": "Block",
    "bytes": "{{value, number}}",
    "chain": "Chain",
    "emerald": "Emerald",
    "failed": "Failed",
    "fiatValue": "Fiat value",
    "fiatValueInUSD": "{{value, currency}}",
    "fill": "Fill",
    "formattedBlockTimestamp": "{{timestamp, dateTime}} ({{distance}})",
    "formattedDateTime": "{{timestamp, dateTime}}",
    "from": "From",
    "gasUsed": "Gas used",
    "gasLimit": "Gas limit",
    "hash": "Hash",
    "height": "Height",
    "lessThanRoseAmount": "< {{value}} ROSE",
    "name": "Name",
    "size": "Size",
    "smartContract": "Smart Contract",
    "success": "Success",
    "status": "Status",
    "ticker": "Ticker",
    "timestamp": "Timestamp",
    "to": "To",
    "totalSent": "Total Sent",
    "transactions": "Transactions",
    "transactionsNumber_one": "1 transaction",
    "transactionsNumber_other": "{{ count }} transactions",
    "txnFee": "Txn Fee",
    "type": "Type",
    "value": "Value",
    "valueInRose": "{{value}} ROSE",
    "viewAll": "View all"
  },
  "nodes": {
    "title": "Active nodes",
    "value": "{{value, number}}",
    "inactive_one": "1 inactive node",
    "inactive_other": "{{ count }} inactive nodes"
  },
  "errors": {
    "unknown": "Unknown error",
    "invalidAddress": "Invalid address",
    "invalidBlockHeight": "Invalid block height",
    "invalidPageNumber": "Invalid page number",
    "invalidTxHash": "Invalid transaction hash",
    "notFoundBlockHeight": "Block not found",
    "notFoundTx": "Transaction not found",
    "validateURL": "Please validate provided URL",
    "validateURLOrGoToFirstTab": "Please check the URL or load the first tab."
  },
  "footer": {
    "mobileTitle": "OPF",
    "title": "Oasis Protocol Foundation",
    "version": "Version: {{buildSha}}"
  },
  "learningMaterials": {
    "emerald": {
      "header": "What is the Emerald network?",
      "description": "The Emerald ParaTime is our official EVM Compatible ParaTime providing smart contract environment with full EVM compatibility."
    },
    "header": "Learning materials",
    "token": {
      "header": "What is the ROSE token?",
      "description": "Rose is the currency powering the Emerald network."
    },
    "transfer": {
      "header": "How to Transfer ROSE into a ParaTime?",
      "description": "Rose is the currency powering the Emerald network."
    }
  },
  "pageTitle": "Oasis Explorer",
  "pageHeader": {
    "status": "ParaTime Online",
    "emerald": "The official confidential EVM Compatible ParaTime providing a smart contract development environment."
  },
  "pagination": {
    "first": "First",
    "last": "Last"
  },
  "roseChart": {
    "header": "Current ROSE Price"
  },
  "social": {
    "description": "Be part of the community and stay in the loop on everything Oasis",
    "discord": "Discord",
    "header": "Join us",
    "reddit": "Reddit",
    "telegram": "Telegram",
    "twitter": "Twitter",
    "youtube": "Youtube"
  },
  "transactions": {
    "latest": "Latest Transactions",
    "method": {
      "consensus": {
        "transaction": "Transaction"
      },
      "evm": {
        "call": "Contract Call",
        "create": "Contract Creation"
      }
    }
  },
  "transaction": {
    "header": "Transaction",
    "warningMultipleTransactionsSameHash": "Please make sure you're looking at the right transaction. There is more than one transaction with this hash, which is extremely rare. We are showing the most recent successful one."
  },
  "transactionStats": {
    "header": "Transactions Per Day",
    "tooltip": "{{value}} tx/day"
  },
  "transactionsTpsChart": {
    "tooltip": "{{value, number}} TPS"
  },
  "select": {
    "placeholder": "Select"
  },
  "chartDuration": {
    "today": "Today",
    "thisWeek": "This week",
    "thisMonth": "This month",
    "allTime": "All time"
  },
  "paraTimeSnapshot": {
    "header": "ParaTime Snapshot"
  },
  "home": {
    "apiOffline": "Our API is offline. We’re trying to reconnect",
    "blocks": "Blocks",
    "exploreBtnText": "Explore Oasis",
    "txn": "Txn",
    "zoomOutBtnText": "Zoom out",
    "helpScreen": {
      "navigate": "Navigate using your finger",
      "pinch": "Pinch to zoom in and out",
      "tap": "Tap to view info",
      "getStarted": "Get Started",
      "infoIconAria": "Toggle info screen"
    }
  },
  "search": {
    "placeholder": "Address, Block, Contract, Txn Hash, Transaction ID, etc",
    "mobilePlaceholder": "Search Address, Block, Txn, etc",
    "searchBtnText": "Search",
    "searchSuggestionsLine1": "Not sure what to look for? Try out a search:",
    "searchSuggestionsLine2": "<BlockLink><BlockIcon/> Block</BlockLink>, <TransactionLink><TransactionIcon/> Transaction</TransactionLink>, <AccountLink><AccountIcon/> Address</AccountLink>"
  }
}
